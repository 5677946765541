<template>
  <div
    class="empty-content"
    :style="{height: `${height}px` }"
  >
    <p class="mb-0">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "EmptyContentComponent",
  props: {
    text: {
      type: String,
    },
    height: {
      type: String,
    },
  },
};
</script>